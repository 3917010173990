/* Forms */

.form-group {
	margin-bottom: 1.5rem;
  }
  .input-group-append,
  .input-group-prepend {
	color: $input-placeholder-color;
	width: auto;
	border: none;
  
	.input-group-text {
	  border-color: $border-color;
	  padding: $input-padding-y .75rem;
	  color: $input-placeholder-color;
	}
  }
  .form-control {
	  border: 1px solid $border-color;
	  font-family: $type-1;
	  font-weight: $font-weight-regular;
	  font-size: $input-font-size;
	  padding:10px 12px; 
	&:focus {
	  background-color: $form-focus-bg;
	  color: $form-focus-color;
	  outline: none;
	}
  }
  .login-box input.form-control:-internal-autofill-selected  {
	  background-color:#fff !important;
	  background-color: -internal-light-dark(#fff, #fff) !important;
  }
  select {
	&.form-control {
	  padding: .4375rem .75rem;
	  border: 0;
	  outline: 1px solid $border-color;
	  color:$gray-light; 
	  &:focus {
		outline: 1px solid $border-color;
	  } 
	}
  }
  .form-group {
	label {
	  font-size: $default-font-size;
	  line-height: 1;
	  vertical-align: top;
	  margin-bottom: .5rem;
	}
	&.has-danger {
	  .form-control {
		border-color: theme-color(danger);
	  }
	}
	.file-upload-default {
	  visibility: hidden;
	  position: absolute;
	}
	.file-upload-info {
	  background: transparent;
	}

	 // Select dropdown  
	 .msl-wrp {
		width:100%;
		.msl{
		  border:1px solid #e4e9f0 !important;
		  border-radius: 2px;
		  padding: 2px 0px;
		  .msl-input-wrp{
			  max-height:50px;
			  overflow: auto;
			  &::-webkit-scrollbar {
				width:2px;
			  } 
			  &::-webkit-scrollbar-thumb {
				background-color:#eee; 
			  }
		  }
		  .msl-input {
			font-size:12px;  
		  }
		  .msl-chip {
			font-size:11px;
			.msl-chip-delete {
				min-width: 14px;
                min-height: 14px;
				height:14px;
                width:14px;
				.msl-close-icn {
					height:7px;
					width: 7px;
				}
			}
		  }
		  .msl-actions {
			top: 19px;  
		  }
		  &.msl-active {
			box-shadow: 0px 0px 0px #c1bebe;
		  }
		}
		.msl-options { 
			border-radius: 2px;
			box-shadow: 0px 0px 5px #c1bebe;
			.msl-option {
				font-size:13px;   
			}
		}
	}
  }
  
  .custom-control-input{
	&:checked ~ {
	  .custom-control-label{
		&::before {
		  color: #fff;
		  border-color: #accc3a !important;
		  background-color: #accc3a;
		  box-shadow: 0px 0px 0px #333 !important;
		}
		&::after {
		  left: calc(-1.8rem + 2px);
		}
	  }
	} 
  }
  
  .custom-switch {
	.custom-control-label{
	  &::before { 
		box-shadow: 0px 0px 0px #333 !important;
		background-color:#d3d7db;
		border:1px solid #d3d7db !important;
		width: 2.75rem;
		height:1.55rem;
		border-radius: 25px;
	  }
	  &::after{
		background-color:#fff; 
		width: calc(1.56rem - 4px);
		height: calc(1.56rem - 4px);
		border-radius: 25px;
	  }
	}
  }
  
   .custom-file-container {
	  margin-top: -5px;
	    label {
		  font-size: 12px;
		  .custom-file-container__image-clear {
			  padding: 0px 3px;
			  font-size: 14px;
			  cursor: pointer;
		  }
	    }
	  .custom-file-container__custom-file {
		  margin-top: 0px;
	  }
	  .custom-file-container__custom-file__custom-file-control {
		  border: 1px solid #e4e9f0 !important;
		  font-size:0.8125rem; 
		  padding: 10px 12px;
		  height: 2.875rem;
		  line-height: 24px;
		  border-radius: 2px;
	  }
	  .custom-file-container__custom-file__custom-file-control__button {
		  border-left: 1px solid #e4e9f0 !important;
		  font-size:13px;
		  font-weight: 500;
		  height: 2.875rem;
		  padding: 10px 12px;
		  line-height: 24px;
	  }
	  .btn-remove-image { 
			margin-top:20px;
			width: 100px;
            position: relative;
		.custom-file-container__image-clear {    
			display: inline-block;
			color: #fff;
            background: #333;
			width: 18px;
			height: 18px;
			line-height: 15px;
			text-align: center;
			border-radius: 20px;
			margin: 4px 4px;
			box-shadow: 0px 0px 3px -1px #898989;
			cursor:pointer;
			position:absolute;
			right:0px;
		  }	
		.custom-file-container__image-preview {
			overflow: auto !important;
			height: 100px !important;
			width: 100px !important;
			text-align: right;
			margin-top:0px;
			
		}
	  }
	  
   }
  
    .product-attr-section{
		.custom-file-container {
			margin-top:0px;
			display: flex;
			label {
				font-size: 12px;
				.custom-file-container__image-clear {
					padding: 0px 3px;
					font-size: 14px;
					cursor: pointer;
				}
			}
			.custom-file-container__custom-file {
				margin-top: 0px;
				margin-right: 8px;
			}
			.custom-file-container__custom-file__custom-file-control {
				border: 1px solid #e4e9f0 !important;
				font-size:0.8125rem; 
				padding: 10px 12px;
				height: 2.875rem;
				line-height: 24px;
				border-radius: 2px;
			}
			.custom-file-container__custom-file__custom-file-control__button {
				border-left: 1px solid #e4e9f0 !important;
				font-size:13px;
				font-weight: 500;
				height: 2.875rem;
				padding: 10px 12px;
				line-height: 24px;
			}
			.btn-remove-image { 
				margin-top:0px;
				width:58px;
				position: relative;
			.custom-file-container__image-clear {    
				display: inline-block;
				color: #fff;
				background: #333;
				width: 18px;
				height: 18px;
				line-height: 15px;
				text-align: center;
				border-radius: 20px;
				margin:0;
				box-shadow: 0px 0px 3px -1px #898989;
				cursor:pointer;
				position:absolute;
				right:0px;
				top: -5px;
				}	
			.custom-file-container__image-preview {
				overflow: auto !important;
				height: 45px !important;
                width: 45px !important;
				text-align: right;
				margin-top:0px;
				margin-bottom: 0px;
			}
			}
			
		}
    }


  .button {
	  background      : #005f95;
	  border          : none;
	  border-radius   : 3px;
	  color           : white;
	  display         : inline-block;
	  font-size       : 19px;
	  font-weight     : bolder;
	  letter-spacing  : 0.02em;
	  padding         : 10px 20px;
	  text-align      : center;
	  text-shadow     : 0px 1px 2px rgba(0, 0, 0, 0.75);
	  text-decoration : none;
	  text-transform  : uppercase;
	  transition      : all 0.2s;
  }
  
  .btn:hover {
	  background : #4499c9;
  }
  
  .btn:active {
	  background : #49ADE5;
  }
  
  input[type="file"] {
	  display : none;
  }
  
  #file-drag {
	  border        : 2px dashed #555;
	  border-radius : 7px;
	  color         : #555;
	  cursor        : pointer;
	  display       : block;
	  font-weight   : bold;
	  margin        : 1em 0;
	  padding       : 3em;
	  text-align    : center;
	  transition    : background 0.3s, color 0.3s;
  }
  
  #file-drag:hover {
	  background : #ddd;
  }
  
  #file-drag:hover,
  #file-drag.hover {
	  border-color : #3070A5;
	  border-style : solid;
	  box-shadow   : inset 0 3px 4px #888;
	  color        : #3070A5;
  }
  
  #file-progress {
	  display : none;
	  margin  : 1em auto;
	  width   : 100%;
  }
  
  #file-upload-btn {
	  margin : auto;
  }
  
  #file-upload-btn:hover {
	  background : #4499c9;
  }
  
  #file-upload-form {
	  margin : auto;	
	  width  : 40%;
  }
  
  progress {
	  appearance    : none;
	  background    : #eee;
	  border        : none;
	  border-radius : 3px;
	  box-shadow    : 0 2px 5px rgba(0, 0, 0, 0.25) inset;
	  height        : 30px;
  }
  
  progress[value]::-webkit-progress-value {
	  background :
		  -webkit-linear-gradient(-45deg, 
			  transparent 33%,
			  rgba(0, 0, 0, .2) 33%, 
			  rgba(0,0, 0, .2) 66%,
			  transparent 66%),
		  -webkit-linear-gradient(right,
			  #005f95,
			  #07294d);
	  background :
		  linear-gradient(-45deg, 
			  transparent 33%,
			  rgba(0, 0, 0, .2) 33%, 
			  rgba(0,0, 0, .2) 66%,
			  transparent 66%),
		  linear-gradient(right,
			  #005f95,
			  #07294d);
	  background-size : 60px 30px, 100% 100%, 100% 100%;
	  border-radius   : 3px;
  }
  
  progress[value]::-moz-progress-bar {
	  background :
	  -moz-linear-gradient(-45deg, 
		  transparent 33%,
		  rgba(0, 0, 0, .2) 33%, 
		  rgba(0,0, 0, .2) 66%,
		  transparent 66%),
	  -moz-linear-gradient(right,
		  #005f95,
		  #07294d);
	  background :
		  linear-gradient(-45deg, 
			  transparent 33%,
			  rgba(0, 0, 0, .2) 33%, 
			  rgba(0,0, 0, .2) 66%,
			  transparent 66%),
		  linear-gradient(right,
			  #005f95,
			  #07294d);
	  background-size : 60px 30px, 100% 100%, 100% 100%;
	  border-radius   : 3px;
  }
