/* Footer */

.sidebar-icon-only {
  .footer {
    @media (min-width: 992px) and (max-width:1300px) {
      width:94%;
    }
  }
}
.footer {
  background:#fff;
  color: $footer-color;
  padding:15px 25px 15px 25px;
  transition: all $action-transition-duration $action-transition-timing-function;
  -moz-transition: all $action-transition-duration $action-transition-timing-function;
  -webkit-transition: all $action-transition-duration $action-transition-timing-function;
  -ms-transition: all $action-transition-duration $action-transition-timing-function;
  font-size: 13px;
  font-family: $type-1;
	font-weight: $font-weight-regular;
  position: fixed;
  bottom: 0px;
  top: auto;
  width: 80.5%;
  z-index:5;
  @media (min-width: 992px) and (max-width:1300px) {
    width: 73.5%;
  }
  a {
    color:$black; 
    font-size: inherit;
  }
  @media (max-width: 991px) {
    margin-left: 0;
    width: 100%;
  }
  @media only screen and (min-width:1200px) and (max-width:1340px) {
    width:94%;
  }
  @media screen and (min-width:1800px) {
    width:97%;
  }
}
